import { Button, Form, Input, PageHeader, Popconfirm, Select, Tabs } from "antd";
import { FormActions } from "../../common/FormActions";
import { useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useNavigationConfirm } from "@/utils/useNavigationConfirm";
import { useBoolean } from "ahooks";
import { deleteReport } from "@/api/reports";
import { ReportTab, useAuth } from "@/utils";
import * as R from "ramda";
import type { FormValues } from "./utils";
import { parseReportFields, prepareReportDto } from "./utils";
import { ReportSummaryForm } from "./ReportSummaryForm";
import { tab } from "@/utils/tab";
import { ReportEmailForm } from "./ReportEmailForm";
import { ReportQueries } from "./ReportQueries";
import { FormErrorsContext } from "../../common/Form/context";
import { ReportSpreadsheet } from "./ReportSpreadsheet";
import { useFormErrorsState } from "@/components/common/Form/hooks";
import { ExportReportButton } from "../ExportReportButton";
import type { Report } from "@/api/reports/types";
import { DownloadSpreadsheetButton } from "./DownloadSpreadsheetButton";
import { ReportActions } from "./ReportActions";
import { useReportMutation } from "@/api/reports/hooks";
import { ReportVariations } from "@/components/Reports/Details/ReportVariations";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

const fieldsByTab = {
  [ReportTab.Summary]: ["name", "recurrency"],
  [ReportTab.Email]: ["email"],
  [ReportTab.Query]: ["queries", "openplayQueries"],
  [ReportTab.Variations]: ["variations"],
  [ReportTab.Spreadsheet]: ["spreadsheet"],
  [ReportTab.Actions]: ["actions"],
};

type Props = {
  initialValues?: Partial<Report>;
  isEditingByDefault?: boolean;
};

export const ReportForm = ({ initialValues = {}, isEditingByDefault = false }: Props) => {
  const [form] = Form.useForm<FormValues>();

  const history = useHistory();
  const { allowNavigation, preventNavigation } = useNavigationConfirm();

  const [editing, { setTrue: enableEditing, setFalse: disableEditing }] = useBoolean(isEditingByDefault);

  const { activeTab = ReportTab.Summary } = useParams<{ activeTab: ReportTab }>();

  const onTabChanged = useCallback(
    (newTab: ReportTab) => history.replace(`/reports/${initialValues?.id || "new"}/${newTab}`),
    [initialValues, history]
  );

  const { fieldsWithErrors, handleFieldsChange, resetErrors } = useFormErrorsState();

  const rootFieldsWithErrors = useMemo(() => fieldsWithErrors.map((field) => field.name[0]), [
    fieldsWithErrors,
  ]);

  const hasTabError = useCallback(
    (tab: ReportTab) => R.intersection(fieldsByTab[tab], rootFieldsWithErrors).length > 0,
    [rootFieldsWithErrors]
  );

  const handleDelete = async () => {
    if (id) {
      await deleteReport(id);
      history.push(`/reports`);
    }
  };

  const initialFields = useMemo(() => parseReportFields(initialValues), [initialValues]);

  const handleCancel = () => {
    allowNavigation();
    if (initialValues.id) {
      form.setFieldsValue(initialFields);
      resetErrors();
      disableEditing();
    } else {
      history.push(`/reports`);
    }
  };

  const handleValueChange = () => {
    if (editing) {
      preventNavigation();
    }
  };

  const handleEdit = () => {
    enableEditing();
  };

  const { isMutating: saving, trigger: save } = useReportMutation(initialValues.id);

  const title = initialValues.name ?? "New Report";

  const handleSave = async (dto) => {
    try {
      const { id } = await save(dto);
      allowNavigation();
      if (!initialFields.id) {
        history.push(`/reports/${id}/${ReportTab.Summary}`);
        return;
      }
      disableEditing();
    } catch (e) {
      console.error("unhandled error", e);
    }
  };

  const handleFinish = async (values: FormValues) => {
    const dto = prepareReportDto(values);
    await handleSave(dto);
  };

  const id = Form.useWatch<string>("id", form);

  const isDeletable = !!id && !editing;
  const isExportable = !!id && !editing;
  const isSpreadsheetDownloadable = !!id && !editing;

  const tabs = useMemo(
    () => [
      tab({
        key: ReportTab.Summary,
        label: "Summary",
        hasErrors: hasTabError(ReportTab.Summary),
        content: <ReportSummaryForm readOnly={!editing} />,
      }),
      tab({
        key: ReportTab.Query,
        label: "Queries",
        hasErrors: hasTabError(ReportTab.Query),
        content: <ReportQueries readOnly={!editing} />,
      }),
      tab({
        key: ReportTab.Variations,
        label: "Variations",
        hasErrors: hasTabError(ReportTab.Variations),
        content: <ReportVariations readOnly={!editing} />,
      }),
      tab({
        key: ReportTab.Email,
        label: "Email",
        hasErrors: hasTabError(ReportTab.Email),
        content: <ReportEmailForm readOnly={!editing} />,
      }),
      tab({
        key: ReportTab.Spreadsheet,
        label: "Spreadsheet",
        hasErrors: hasTabError(ReportTab.Spreadsheet),
        content: <ReportSpreadsheet readOnly={!editing} />,
      }),
      tab({
        key: ReportTab.Actions,
        label: "Actions",
        hasErrors: hasTabError(ReportTab.Actions),
        content: <ReportActions readOnly={!editing} />,
      }),
    ],
    [editing, hasTabError]
  );

  useEffect(() => {
    if (history.location.state?.editMode) {
      enableEditing();
    }
  }, [history.location.state?.editMode, enableEditing]);

  const { ability } = useAuth();

  const canEdit = ability.can(PermissionAction.Update, PermissionSubject.Report);
  const canDelete = ability.can(PermissionAction.Delete, PermissionSubject.Report);

  return (
    <>
      <PageHeader
        title={<h1 style={{ margin: 0 }}>{title}</h1>}
        extra={
          <>
            {isExportable && <ExportReportButton id={id} />}
            {isSpreadsheetDownloadable && <DownloadSpreadsheetButton form={form} />}
            <FormActions
              isEditing={editing}
              onSave={form.submit}
              isSaving={saving}
              onEdit={handleEdit}
              onCancel={handleCancel}
              canEdit={canEdit}
            />
            {isDeletable && (
              <Popconfirm
                title="Are you sure to delete the report?"
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
                disabled={!canDelete}
              >
                <Button disabled={!canDelete}>Delete</Button>
              </Popconfirm>
            )}
          </>
        }
      />
      <FormErrorsContext.Provider value={{ fieldsWithErrors }}>
        <Form
          form={form}
          initialValues={initialFields}
          onFinish={handleFinish}
          onFieldsChange={handleFieldsChange}
          onValuesChange={handleValueChange}
          validateMessages={{ required: "Field is mandatory" }}
          labelAlign="left"
        >
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item hidden name="variables">
            <Select mode="multiple" />
          </Form.Item>
          <Tabs activeKey={activeTab} onChange={onTabChanged} items={tabs} />
        </Form>
      </FormErrorsContext.Provider>
    </>
  );
};
