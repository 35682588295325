import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { Select } from "antd";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  action?: PermissionAction;
};

const exceptDeleting = [
  PermissionAction.Create,
  PermissionAction.Update,
  PermissionAction.Read,
  PermissionAction.Manage,
];

const readOnly = [PermissionAction.Read];

const subjects = [
  { name: PermissionSubject.All, label: "All" },
  { name: PermissionSubject.Release, label: "Release", availableActions: readOnly },
  { name: PermissionSubject.User, label: "User" },
  { name: PermissionSubject.Feed, label: "Feed" },
  {
    name: PermissionSubject.Partner,
    label: "Partner",
    availableActions: exceptDeleting,
  },
  { name: PermissionSubject.ExportSchedule, label: "Export Schedule" },
  { name: PermissionSubject.Template, label: "Template" },
  { name: PermissionSubject.Transfer, label: "Activity", availableActions: readOnly },
  { name: PermissionSubject.SalesforceObject, label: "Salesforce Object" },
  {
    name: PermissionSubject.OpenplayObject,
    label: "OpenPlay Object",
    availableActions: [PermissionAction.Update, PermissionAction.Read, PermissionAction.Manage],
  },
  {
    name: PermissionSubject.OpenplayQuery,
    label: "OpenPlay Query",
  },
  {
    name: PermissionSubject.Report,
    label: "Report",
  },
  {
    name: PermissionSubject.EmailAlert,
    label: "Email Alert",
  },
  {
    name: PermissionSubject.OnDemandReport,
    label: "On-Demand Report",
  },
  {
    name: PermissionSubject.SpreadsheetColumnSet,
    label: "Spreadsheet Column Set",
  },
  {
    name: PermissionSubject.Webhook,
    label: "Webhook",
    availableActions: [PermissionAction.Update, PermissionAction.Read, PermissionAction.Manage],
  },
  {
    name: PermissionSubject.Flow,
    label: "Flow",
  },
  {
    name: PermissionSubject.FlowEvent,
    label: "Flow Event",
  },
  {
    name: PermissionSubject.Script,
    label: "Script",
    availableActions: [PermissionAction.Update, PermissionAction.Read, PermissionAction.Manage],
  },
  {
    name: PermissionSubject.Settings,
    label: "Settings",
  },
];

export const PermissionSubjectPicker = ({ value, onChange, action }: Props) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select subject"
      options={subjects.map((entry) => ({
        value: entry.name,
        label: entry.label,
        disabled: action && entry.availableActions ? !entry.availableActions.includes(action) : false,
      }))}
      suffixIcon={<Icon component={DropdownArrow} />}
      disabled={!action}
    />
  );
};
