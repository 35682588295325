import "./App.less";
import { useState } from "react";
import { Layout } from "antd";
import { Redirect, Route, Switch } from "react-router-dom";
import { FeedForm, FeedManualExport } from "./components/Feeds";
import { PartnersList } from "./components/Partners";
import { ReleaseDeliveries, ReleasesList } from "./components/Releases";
import { TemplatesList } from "./components/Templates";
import { Header } from "./components/Layout/Header/Header";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import Breadcrumbs from "./components/Layout/Breadcrumbs";
import { PrivateRoute } from "./utils";
import { useBoolean, useLocalStorageState } from "ahooks";
import { LayoutContext } from "./contexts/layout";
import { FeedPage } from "./components/Feeds/Details/FeedPage";
import { FeedActivity } from "./components/Feeds/Activity/FeedActivity";
import { ActivityPage } from "./components/Activity/ActivityPage";
import { Partner } from "./components/Partners/Details/Partner";
import { PartnerForm } from "./components/Partners/Details/PartnerForm";
import { SettingsPage } from "./components/Settings/SettingsPage";
import { TemplatePage } from "./components/Templates/Details/TemplatePage";
import { TemplateForm } from "./components/Templates/Details/TemplateForm";
import { Sidebar } from "./components/Layout/Sidebar/Sidebar";
import { ReportsList } from "./components/Reports/List/ReportsList";
import { ReportPage } from "./components/Reports/Details/ReportPage";
import { ReportForm } from "./components/Reports/Details/ReportForm";
import { Main } from "./components/Layout/Main/Main";
import cn from "classnames";
import { PartnerActivity } from "@/components/Partners/Activity/PartnerActivity";
import { WebhooksActivityPage } from "@/components/Webhooks/Activity/WebhooksActivityPage";
import { WebhookList } from "@/components/Webhooks/List/WebhookList";
import { WebhookPage } from "./components/Webhooks/Details/WebhookPage";
import { EmailAlertsList } from "@/components/EmailAlerts/List/EmailAlertsList";
import { EmailAlertForm } from "@/components/EmailAlerts/Details/EmailAlertForm";
import { EmailAlertPage } from "@/components/EmailAlerts/Details/EmailAlertPage";
import { WebhookActivityPage } from "@/components/Webhooks/Details/WebhookActivityPage";
import { MessageType } from "@/api/webhooks/types";
import { FlowsList } from "@/components/Flows/List/FlowsList";
import { FlowForm } from "@/components/Flows/Details/FlowForm";
import { FlowPage } from "@/components/Flows/Details/FlowPage";
import { FlowEventList } from "@/components/Flows/FlowEventList/FlowEventList";
import { SalesforceObjectList } from "@/components/SalesforceObjects/List/SalesforceObjectList";
import { ColumnSetPage } from "@/components/Spreadsheets/ColumnSets/Details/ColumnSetPage";
import { ColumnSetForm } from "@/components/Spreadsheets/ColumnSets/Details/ColumnSetForm";
import { ColumnSetList } from "@/components/Spreadsheets/ColumnSets/List/ColumnSetList";
import { OpenplayObjectList } from "@/components/OpenplayObjects/List/OpenplayObjectList";
import { OpenplayObjectPage } from "@/components/OpenplayObjects/Details/OpenplayObjectPage";
import { ExportScheduleList } from "@/components/ExportSchedules/List/ExportScheduleList";
import { ExportScheduleForm } from "@/components/ExportSchedules/Details/ExportScheduleForm";
import { ExportSchedulePage } from "@/components/ExportSchedules/Details/ExportSchedulePage";
import { ScriptList } from "@/components/Scripts/List/ScriptList";
import { ScriptPage } from "@/components/Scripts/Details/ScriptPage";
import { UserPage } from "@/components/Users/Details/UserPage";
import { UserForm } from "@/components/Users/Details/UserForm";
import { UsersList } from "@/components/Users/List";
import { UserProfile } from "@/components/Users/Profile";
import { OpenplayQueryForm } from "@/components/OpenplayQueries/Details/OpenplayQueryForm";
import { OpenplayQueryPage } from "@/components/OpenplayQueries/Details/OpenplayQueryPage";
import { OpenplayQueryList } from "@/components/OpenplayQueries/List/OpenplayQueryList";
import { OnDemandReportForm } from "@/components/OnDemandReports/Details/OnDemandReportForm";
import { OnDemandReportPage } from "@/components/OnDemandReports/Details/OnDemandReportPage";
import { OnDemandReportList } from "@/components/OnDemandReports/List/OnDemandReportList";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

const webhookTypeUrlPart = `:type(${Object.values(MessageType).join("|")})`;

function App() {
  const [isSecondary, { setFalse: setPrimary, setTrue: setSecondary }] = useBoolean(false);
  const [isFullWidth, setFullWidth] = useState(false);
  const [isNavigateAllowed, { setFalse: preventNavigation, setTrue: allowNavigation }] = useBoolean(true);

  const [isMenuCollapsed, setIsMenuCollapsed] = useLocalStorageState("sidebar", false);

  const handleMenuCollapse = () => {
    setIsMenuCollapsed((value) => !value);
  };

  return (
    <Layout className="app">
      <LayoutContext.Provider
        value={{
          isSecondary,
          setSecondary,
          setPrimary,
          isNavigateAllowed,
          preventNavigation,
          allowNavigation,
          setFullWidth,
        }}
      >
        <Sidebar collapsed={isMenuCollapsed} />
        <Layout className={cn("app__content", isSecondary && "app__content--secondary")}>
          <Header className="app__header" isMenuCollapsed={isMenuCollapsed} onCollapse={handleMenuCollapse} />
          <div className="app__scroller">
            <Main fullWidth={isFullWidth}>
              <Breadcrumbs />
              <Switch>
                <PrivateRoute
                  path="/releases/:id"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.Release]]}
                >
                  <ReleaseDeliveries />
                </PrivateRoute>
                <PrivateRoute
                  path="/releases"
                  permissions={[[PermissionAction.Read, PermissionSubject.Release]]}
                >
                  <ReleasesList />
                </PrivateRoute>
                <PrivateRoute
                  path="/partners/new/:activeTab"
                  permissions={[[PermissionAction.Create, PermissionSubject.Partner]]}
                  secondaryScreen
                >
                  <PartnerForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute
                  path="/partners/:partnerId/feeds/new/:activeTab"
                  permissions={[[PermissionAction.Create, PermissionSubject.Feed]]}
                  secondaryScreen
                >
                  <FeedForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute
                  path="/partners/:partnerId/feeds/:feedId/manual-export"
                  exact
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.Feed]]}
                >
                  <FeedManualExport />
                </PrivateRoute>
                <PrivateRoute
                  path="/partners/:partnerId/feeds/:feedId/activity"
                  exact
                  secondaryScreen
                  permissions={[
                    [PermissionAction.Read, PermissionSubject.Feed],
                    [PermissionAction.Read, PermissionSubject.Transfer],
                  ]}
                >
                  <FeedActivity />
                </PrivateRoute>
                <PrivateRoute
                  path={"/partners/:partnerId/feeds/:feedId/:activeTab"}
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.Feed]]}
                >
                  <FeedPage />
                </PrivateRoute>
                <Route
                  path="/partners/:partnerId/feeds/:feedId"
                  exact
                  render={({ location }) => <Redirect to={`${location.pathname}/summary`} />}
                />
                <PrivateRoute
                  path={[
                    "/partners/:partnerId/activity/:targetObject(release)/:operation(import|export)",
                    "/partners/:partnerId/activity/:targetObject(project)/:operation(export)",
                  ]}
                  exact
                  secondaryScreen
                  permissions={[
                    [PermissionAction.Read, PermissionSubject.Partner],
                    [PermissionAction.Read, PermissionSubject.Transfer],
                  ]}
                >
                  <PartnerActivity />
                </PrivateRoute>
                <Route
                  path="/partners/:partnerId/activity"
                  render={({
                    match: {
                      params: { partnerId },
                    },
                  }) => <Redirect to={`/partners/${partnerId}/activity/release/export`} />}
                />
                <PrivateRoute
                  path="/partners/:partnerId/:activeTab"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.Partner]]}
                >
                  <Partner />
                </PrivateRoute>
                <Route
                  path="/partners/:partnerId"
                  exact
                  render={({ location }) => <Redirect to={`${location.pathname}/summary`} />}
                />
                <PrivateRoute
                  path="/partners"
                  permissions={[[PermissionAction.Read, PermissionSubject.Partner]]}
                >
                  <PartnersList />
                </PrivateRoute>
                <PrivateRoute
                  path="/users/new"
                  secondaryScreen
                  permissions={[[PermissionAction.Create, PermissionSubject.User]]}
                >
                  <UserForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute
                  path="/users/:id"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.User]]}
                >
                  <UserPage />
                </PrivateRoute>
                <PrivateRoute path="/users" permissions={[[PermissionAction.Read, PermissionSubject.User]]}>
                  <UsersList />
                </PrivateRoute>
                <PrivateRoute
                  path="/templates/new"
                  secondaryScreen
                  permissions={[[PermissionAction.Create, PermissionSubject.Template]]}
                >
                  <TemplateForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute
                  path="/templates/:id"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.Template]]}
                >
                  <TemplatePage />
                </PrivateRoute>
                <PrivateRoute
                  path="/templates"
                  permissions={[[PermissionAction.Read, PermissionSubject.Template]]}
                >
                  <TemplatesList />
                </PrivateRoute>
                <PrivateRoute
                  path="/activity/:targetObject"
                  fullWidth
                  permissions={[[PermissionAction.Read, PermissionSubject.Transfer]]}
                >
                  <ActivityPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/settings"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.Settings]]}
                >
                  <SettingsPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/reports/new/:activeTab"
                  secondaryScreen
                  permissions={[[PermissionAction.Create, PermissionSubject.Report]]}
                >
                  <ReportForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute
                  path="/reports/:id/:activeTab"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.Report]]}
                >
                  <ReportPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/reports"
                  permissions={[[PermissionAction.Read, PermissionSubject.Report]]}
                >
                  <ReportsList />
                </PrivateRoute>
                <PrivateRoute
                  path="/column-sets/new"
                  secondaryScreen
                  permissions={[[PermissionAction.Create, PermissionSubject.SpreadsheetColumnSet]]}
                >
                  <ColumnSetForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute
                  path="/column-sets/:id"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.SpreadsheetColumnSet]]}
                >
                  <ColumnSetPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/column-sets"
                  permissions={[[PermissionAction.Read, PermissionSubject.SpreadsheetColumnSet]]}
                >
                  <ColumnSetList />
                </PrivateRoute>
                <PrivateRoute
                  path="/export-schedules/new"
                  secondaryScreen
                  permissions={[[PermissionAction.Create, PermissionSubject.ExportSchedule]]}
                >
                  <ExportScheduleForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute
                  path="/export-schedules/:id"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.ExportSchedule]]}
                >
                  <ExportSchedulePage />
                </PrivateRoute>
                <PrivateRoute
                  path="/export-schedules"
                  permissions={[[PermissionAction.Read, PermissionSubject.ExportSchedule]]}
                >
                  <ExportScheduleList />
                </PrivateRoute>
                <PrivateRoute
                  path="/webhook-activity"
                  permissions={[[PermissionAction.Read, PermissionSubject.Webhook]]}
                >
                  <WebhooksActivityPage />
                </PrivateRoute>
                <PrivateRoute
                  path={`/webhooks/${webhookTypeUrlPart}/activity`}
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.Webhook]]}
                >
                  <WebhookActivityPage />
                </PrivateRoute>
                <PrivateRoute
                  path={`/webhooks/${webhookTypeUrlPart}`}
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.Webhook]]}
                >
                  <WebhookPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/webhooks"
                  permissions={[[PermissionAction.Read, PermissionSubject.Webhook]]}
                >
                  <WebhookList />
                </PrivateRoute>
                <PrivateRoute
                  path="/email-alerts/new/:activeTab"
                  secondaryScreen
                  permissions={[[PermissionAction.Create, PermissionSubject.EmailAlert]]}
                >
                  <EmailAlertForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute
                  path="/email-alerts/:id/:activeTab"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.EmailAlert]]}
                >
                  <EmailAlertPage />
                </PrivateRoute>
                <Route
                  path="/email-alerts/:id"
                  exact
                  render={({ location }) => <Redirect to={`${location.pathname}/summary`} />}
                />
                <PrivateRoute
                  path="/email-alerts"
                  permissions={[[PermissionAction.Read, PermissionSubject.EmailAlert]]}
                >
                  <EmailAlertsList />
                </PrivateRoute>
                <PrivateRoute
                  path="/flow-events"
                  permissions={[[PermissionAction.Read, PermissionSubject.FlowEvent]]}
                >
                  <FlowEventList />
                </PrivateRoute>
                <PrivateRoute
                  path="/flows/new"
                  secondaryScreen
                  permissions={[[PermissionAction.Create, PermissionSubject.Flow]]}
                >
                  <FlowForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute
                  path="/flows/:id"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.Flow]]}
                >
                  <FlowPage />
                </PrivateRoute>
                <PrivateRoute path="/flows" permissions={[[PermissionAction.Read, PermissionSubject.Flow]]}>
                  <FlowsList />
                </PrivateRoute>
                <PrivateRoute
                  path="/scripts/:key"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.Script]]}
                >
                  <ScriptPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/scripts"
                  permissions={[[PermissionAction.Read, PermissionSubject.Script]]}
                >
                  <ScriptList />
                </PrivateRoute>
                <PrivateRoute
                  path="/openplay-objects/:name"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.OpenplayObject]]}
                >
                  <OpenplayObjectPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/openplay-objects"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.OpenplayObject]]}
                >
                  <OpenplayObjectList />
                </PrivateRoute>
                <PrivateRoute
                  path="/openplay-queries/new"
                  secondaryScreen
                  permissions={[[PermissionAction.Create, PermissionSubject.OpenplayQuery]]}
                >
                  <OpenplayQueryForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute
                  path="/openplay-queries/:id"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.OpenplayQuery]]}
                >
                  <OpenplayQueryPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/openplay-queries"
                  permissions={[[PermissionAction.Read, PermissionSubject.OpenplayQuery]]}
                >
                  <OpenplayQueryList />
                </PrivateRoute>
                <PrivateRoute
                  path="/on-demand-reports/new/:activeTab"
                  secondaryScreen
                  permissions={[[PermissionAction.Create, PermissionSubject.OnDemandReport]]}
                >
                  <OnDemandReportForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute
                  path="/on-demand-reports/:id/:activeTab"
                  secondaryScreen
                  permissions={[[PermissionAction.Read, PermissionSubject.OnDemandReport]]}
                >
                  <OnDemandReportPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/on-demand-reports"
                  permissions={[[PermissionAction.Read, PermissionSubject.OnDemandReport]]}
                >
                  <OnDemandReportList />
                </PrivateRoute>
                <PrivateRoute
                  permissions={[[PermissionAction.Read, PermissionSubject.SalesforceObject]]}
                  path="/salesforce-objects"
                >
                  <SalesforceObjectList />
                </PrivateRoute>
                <PrivateRoute path="/profile" secondaryScreen>
                  <UserProfile />
                </PrivateRoute>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/reset-password">
                  <ResetPassword />
                </Route>
                <Route path="/">
                  <Redirect to="/releases" />
                </Route>
              </Switch>
            </Main>
          </div>
        </Layout>
      </LayoutContext.Provider>
    </Layout>
  );
}

export default App;
