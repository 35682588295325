import type { Status, UserRole } from "@/utils";
import type { Pagination } from "@/api/types";

type Partner = {
  id: string;
  name: string;
  ddexPartyId: string;
  address?: string;
  primaryContactName: string;
  primaryContactEmail: string;
  primaryContactPhone?: string;
  status: Status;
  deactivationReason: string;
  usePreorderDate?: boolean;
  onHoldDays?: number;
  createdAt: string;
  updatedAt: string;
};

export type User = {
  id: string;
  externalId: string;
  name: string;
  email: string;
  role: UserRole;
  status: Status;
  timeZone?: string;
  createdAt: string;
  updatedAt: string;
  permissions: Permission[];
  partners: Partner[];
};

export type UserFilters = Pagination & {
  name?: string;
  nameContains?: string;
  email?: string;
  role?: UserRole;
  status?: Status;
};

export type CreateUserDto = Partial<User> & { status: Status };

export enum PermissionType {
  Can = "can",
  Cannot = "cannot",
}

export enum PermissionSubject {
  All = "all",
  Release = "Release",
  User = "User",
  Feed = "Feed",
  Partner = "Partner",
  ExportSchedule = "ExportSchedule",
  Template = "Template",
  Transfer = "Transfer",
  SalesforceObject = "SalesforceObject",
  OpenplayObject = "OpenplayObject",
  OpenplayQuery = "OpenplayQuery",
  Report = "Report",
  EmailAlert = "EmailAlert",
  OnDemandReport = "OnDemandReport",
  SpreadsheetColumnSet = "SpreadsheetColumnSet",
  Webhook = "Webhook",
  Flow = "Flow",
  FlowEvent = "FlowEvent",
  Script = "Script",
  Settings = "Settings",
}

export enum PermissionAction {
  Manage = "manage",
  Create = "create",
  Read = "read",
  Update = "update",
  Delete = "delete",
}

export type Permission = {
  id: string;
  type: PermissionType;
  action: PermissionAction;
  subject: PermissionSubject;
};

export type CurrentUser = { userId: string } & Pick<
  User,
  "externalId" | "email" | "status" | "role" | "permissions"
>;
