import type { UploadFile } from "antd";
import { Button, Col, Form, Input, Modal, Row, Upload } from "antd";
import { useBoolean } from "ahooks";
import { Field } from "@/components/common/Form/Field";
import { isUniqueName } from "@/utils/validation";
import { checkReportExistence, importReport } from "@/api/reports";
import { ImportOutlined, UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { ErrorCode } from "@/api/errorCode";

type UploadButtonProps = {
  file?: UploadFile;
  onChange?: (fileList: UploadFile) => void;
};

const UploadButton = ({ file, onChange }: UploadButtonProps) => {
  return (
    <Upload
      beforeUpload={() => false}
      fileList={file ? [file] : []}
      accept=".json"
      maxCount={1}
      onChange={(info) => onChange(info.fileList[0] ?? null)}
    >
      <Button icon={<UploadOutlined />}>Upload .JSON</Button>
    </Upload>
  );
};

type FormValues = {
  name: string;
  includeSpreadsheet: boolean;
  file: UploadFile;
};

export const ImportReportButton = () => {
  const history = useHistory();

  const [form] = Form.useForm<FormValues>();
  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);

  const handleCancel = () => {
    form.resetFields();
    close();
  };

  const handleFinish = async (values: FormValues) => {
    const fd = new FormData();
    fd.append("name", values.name);
    fd.append("file", values.file.originFileObj, values.file.fileName);
    try {
      const report = await importReport(fd);
      close();
      history.push(`/reports/${report.id}/summary`);
    } catch (error) {
      if (error.errorCode === ErrorCode.DataValidation) {
        form.setFields([{ name: "file", errors: [`Failed to import: ${error.message}`] }]);
      }
      console.error(error);
    }
  };

  return (
    <>
      <Button icon={<ImportOutlined />} onClick={open}>
        Import from .JSON
      </Button>
      <Modal
        open={isOpen}
        title="Import From .JSON"
        onCancel={handleCancel}
        onOk={form.submit}
        okText="Import"
      >
        <Form form={form} onFinish={handleFinish}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Field
                name="name"
                validateTrigger={["onChange", "onBlur"]}
                rules={[
                  { required: true, message: "Field is mandatory" },
                  isUniqueName(checkReportExistence, "A report with entered name already exists in the system"),
                ]}
              >
                <Input placeholder="New Report Name" />
              </Field>
            </Col>
            <Col span={24}>
              <Field
                name="file"
                valuePropName="file"
                rules={[{ required: true, message: "Please, attach the .json file" }]}
              >
                <UploadButton />
              </Field>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
